import RequestAPI from "./RequestAPI";

export class Account extends RequestAPI {
    /**
     * Login user to app
     * @param username
     * @param password
     * @returns {*}
     */
    login(username, password) {
        return this.request(
            'POST',
            '/login',
            {},
            {username, password},
        );
    }

    /**
     * Get new token with refresh Token
     * @param refreshToken
     * @returns {*}
     */
    getNewToken(refreshToken) {
        return this.request(
            'POST',
            '/token',
            {},
            {refresh_token: refreshToken},
        )
    }

    /**
     * Check user token reset validity
     * @param token
     * @returns {*}
     */
    checkValidityToken(token) {
        return this.request(
            'POST',
            '/users/reset-token-validity',
            {},
            {token},
        );
    }

}

export default Account;
