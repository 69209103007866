import {combineReducers} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';

import user from './user';
import project from './project';

/**
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducer = combineReducers({
    user,
    project,
    toastr: toastrReducer,
});

export default rootReducer;
