import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { projects } from '../actions/projectsTemp';

const ProjectDetail = () => {
    const params = useParams();
    const currentProject = projects.find(item => item.title === params.slug);
    //const dispatch = useDispatch();
    // const projects = useSelector(getProjects);

    // useEffect(() => {
    //     dispatch(loadProjects());
    // }, [dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="project-detail">
            <div className="flex justify-center">
                <img src={require('../img/logo.svg')} alt='Dylan DUFAY' />
            </div>
            <div className="project-detail__title" data-aos="fade-right">{ currentProject.title }</div>
            <div className="project-detail__date" data-aos="fade-right">{ currentProject.date }</div>
            <div className="project-detail__content" data-aos="fade-right">
                <div className="section__title">Context</div>
                <div className="section__bordered-content">
                { currentProject.context }
                </div>
                <div className="img__container" data-aos="fade-right" data-aos-delay="200">
                    <img src={require(`../img/${currentProject.picture}`)} alt={currentProject.title} className="img-fluid" />
                </div>

                <div className="section__title" style={{ marginTop: 100 }}>Global view</div>
                <div className="content__list" data-aos="fade-right" data-aos-delay="200">
                    <div className="flex">
                        <div className="section__bordered-content">
                            { currentProject.globalView1 }
                        </div>
                        <div className="img__container">
                            <img src={require(`../img/${currentProject.globalView1Picture}`)} alt={currentProject.globalView1} className="img-fluid" />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="img__container">
                            <img src={require(`../img/${currentProject.globalView2Picture}`)} alt={currentProject.globalView2} className="img-fluid" />
                        </div>
                        <div className="section__bordered-content">
                            { currentProject.globalView2 }
                        </div>
                    </div>
                </div>
                <div className="section__title" data-aos="fade-right" data-aos-delay="200">Technologies</div>
                <div className="skills__content" data-aos="fade-right" data-aos-delay="200">
                    { currentProject.detailTechnologies.map((techno, key) => {
                            return <div className="skills__list" key={Object.keys(techno)[0]}>
                            <h4 className="section__subtitle">{Object.keys(techno)[0]}</h4>
                            <ul>
                                { Object.values(techno)[0].map(item => <li key={item}>{item}</li>) }
                            </ul>
                        </div>
                    }) }
                </div>
                <br/>
                {/* <div className="section__title" style={{ marginTop: 100 }}>Useful links</div> */}
            </div>
        </div>
    );
}

export default ProjectDetail;
