import React from 'react';

const About = () => {
    return (
        <div className='about' data-aos="fade-right" data-aos-delay="100">
            <div className="section__title">About</div>
            <div className="section__bordered-content about__content">
                I’m a passionate full stack developer 🚀 coding with the JAMStack idea.
                Based in Paris (France), I’m working on severals projects in Symfony, ReactJS
                and many tools around their Frameworks. I love to create new things and learn new tools 📚
            </div>
        </div>
    );
};

export default About;