import {toastr} from 'react-redux-toastr';

import {api} from '../lib/api';
import { makeActionCreator } from '../helpers/helper';

export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST';
export const setProjectsList = makeActionCreator(SET_PROJECTS_LIST, 'projects');

export const loadProjects = filters => dispatch => {
    api.project.getAll(filters)
        .then(projects => dispatch(setProjectsList(projects)))
        .catch(() => toastr.error('Erreur', 'Une erreur est survenue lors de la récupération des projects.'))
    ;
};

export const postProject = project => dispatch => {
    api.project.create(project)
        .then(project => toastr.success('Projet créé', `Le projet ${project.title} a été créé`))
        .catch(() => toastr.error('Erreur', 'Une erreur est survenue lors de la création du project.'))
    ;
};
