import decode from 'jwt-decode';

import api from './api';

export class Authentication {
    /**
     * Intercept axios request and add token to it before dispatch
     * @param instance
     */
    addWebTokenToRequest(instance) {
        const _this = this;
        instance.interceptors.request.use(function (config) {
            const refreshToken = _this.getRefreshToken();
            const token = _this.getToken();

            if (!(config.url).includes('/login') && !(config.url).includes('/token')) {
                if (token && !_this.isTokenExpired()) {
                    config.headers.authorization = `Bearer ${token}`;
                    return config;
                } else if (refreshToken) {
                    return api.account.getNewToken(refreshToken).then(res => {
                        config.headers.authorization = `Bearer ${res.data.token}`;
                        _this.setUserTokens(res.data.token, res.data.refresh_token);

                        return Promise.resolve(config);
                    }).catch(() => {
                        _this.deleteTokens();
                        return Promise.resolve(config);
                    });
                } else {
                    return config;
                }
            } else {
                return config;
            }
        }, function (error) {
            return Promise.reject(error);
        });
    }

    /**
     * Intercept request and redirect user if status code is unauthorized
     * @param instance
     */
    checkRequestResponse(instance) {
        const _this = this;
        instance.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            const responseURL = error.response && error.response.config.url;
            if (responseURL && error.response.status === 401 && !responseURL.includes('/login')) {
                _this.deleteTokens();
            }
            return Promise.reject(error);
        });
    }

    /**
     * Check if user token is expired
     * @returns {boolean}
     */
    isTokenExpired() {
        const userData = this.getUserData();

        if (userData && userData.exp) {
            return new Date() >= new Date(userData.exp * 1000);
        }

        return true;
    }

    /**
     * Set token and refresh token for user
     * @param token
     * @param refreshToken
     */
    setUserTokens(token, refreshToken) {
        localStorage.setItem('token', token);
        localStorage.setItem('refresh-token', refreshToken);
    }

    /**
     * Get token for user
     * @returns {string | null}
     */
    getToken() {
        return localStorage.getItem('token');
    }

    /**
     * Get refresh token for user
     * @returns {string | null}
     */
    getRefreshToken() {
        return localStorage.getItem('refresh-token');
    }

    /**
     * Delete token & refresh token in localStorage
     */
    deleteTokens(deleteAll = false) {
        localStorage.removeItem('token');

        if (deleteAll) {
            localStorage.removeItem('refresh-token');
        }
    }

    /**
     * Get decoded news from token
     * @returns {*}
     */
    getUserData() {
        if (this.getToken()) {
            return decode(this.getToken());
        }

        return null;
    }
}

export default Authentication;
