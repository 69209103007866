import React from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

import Presentation from './components/Presentation';
import { Works } from "./components/Works";
import Projects from './components/Projects';
import About from './components/About';
import Skills from './components/Skills';

Aos.init({
  once: true,
  duration: 800,
});

function App({ children }) {
  return (
    <div className="home">
        <div className="flex justify-center">
            <img src={require('./img/logo.svg')} alt='Dylan DUFAY' />
        </div>
        <Presentation />
        <div className="container">
            <About />
            <Skills />
            <Works />
            <Projects />
        </div>
    </div>
  );
}

export default App;
