import RequestAPI from "./RequestAPI";

export class Project extends RequestAPI {
    get(id) {
        return this.request(
            'GET',
            `/projects/${id}`,
            {}
        )
            .then(response => response.data);
    }

    getAll(filters) {
       return this.request(
           'GET',
           '/projects',
           filters
       )
           .then(response => response.data);
   }

    delete(id) {
        return this.request(
            'DELETE',
            `/projects/${id}`,
        )
    }

    edit(id, values) {
        return this.request(
            'PUT',
            `/projects/${id}`,
            {},
            values
        )
            .then(response => response.data);
    }

    create(data) {
        return this.request(
            'POST',
            '/projects',
            {},
            data
        )
            .then(response => response.data);
    }


}

export default Project;
