import Authentication from './Authentication';
import Account from "./Account";
import Project from "./Project";

export class ClientAPI {
    constructor(baseURL) {
        this.baseURL = baseURL;
        this.auth = new Authentication();
        this.account = new Account(baseURL);
        this.project = new Project(baseURL);
    }

    authentication() {
        return this.auth;
    }

    account() {
        return this.account;
    }

    project() {
        return this.project;
    }
}

export default ClientAPI;
