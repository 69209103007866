export const works = [
    {
        id: 1,
        title: 'Full-Stack developer',
        company: 'Mentalworks',
        description: 'I\'m currently working on multiple web and mobile projects using ReactJS with NextJS, Symfony, Sylius. I\'m also mentoring some colleagues to enhance their competences and skills.',
        date: 'February 2021 - Today',
        technologies: 'ReactJS | Symfony | Sylius | Integration',
    },
    {
        id: 2,
        title: 'Full-Stack developer',
        company: 'Nodevo',
        description: 'During this contract I worked on several projects for major groups like TF1 or Arte.',
        date: 'August 2019 - January 2021',
        technologies: 'ReactJS | Symfony | Integration',
    },
    {
        id: 3,
        title: 'Work-study - Full-Stack developer',
        company: 'Nodevo',
        description: 'During this contract I learned how to work in groups, working with experimented peoples to deliver quality code (scalable and easy to maintain).',
        date: 'August 2018 - August 2019',
        technologies: 'ReactJS | VueJS | Symfony',
    },
    {
        id: 4,
        title: 'Work-study - Web developer',
        company: 'Mentalworks',
        description: 'During this contract I learned to code in PHP and to use the Symfony framework.',
        date: 'September 2017 - August 2018',
        technologies: 'Symfony | Zend | Silex',
    },
    {
        id: 5,
        title: 'Fixed-term contract - Web developer',
        company: 'Mentalworks',
        description: 'During this contract I learned to code in PHP and to use the Symfony framework.',
        date: 'August 2017 - September 2017',
        technologies: 'Symfony',
    },
];
