import React from 'react';

const Skills = () => {
    return (
        <div className='skills' data-aos="fade-right" data-aos-delay="100">
            <div className="section__title">Skills</div>
            <div className="skills__content">
                <div className="skills__list">
                    <h4 className="section__subtitle">Front-end</h4>
                    <ul>
                        <li>Javascript</li>
                        <li>React</li>
                        <li>React native</li>
                        <li>CSS</li>
                        <li>SCSS</li>
                        <li>HTML</li>
                    </ul>
                </div>
                <div className="skills__list">
                    <h4 className="section__subtitle">Back-end</h4>
                    <ul>
                        <li>NodeJS</li>
                        <li>PHP</li>
                        <li>Symfony</li>
                        <li>Zend</li>
                        <li>Sylius</li>
                    </ul>
                </div>
                <div className="skills__list">
                    <h4 className="section__subtitle">Database</h4>
                    <ul>
                        <li>MySQL</li>
                        <li>PostreSQL</li>
                        <li>MariaDB</li>
                    </ul>
                </div>
                <div className="skills__list">
                    <h4 className="section__subtitle">Tools</h4>
                    <ul>
                        <li>Adobe XD</li>
                        <li>MAC OS</li>
                        <li>Docker</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Skills;