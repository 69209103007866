import { createReducer } from "../helpers/helper";
import { LOGIN_USER, LOGOUT_USER } from "../actions/user";
import api from '../lib/api';

const initialState = {
    user: {},
    isUserLoggedIn: !api.authentication().isTokenExpired(),
};

const actionHandlers = {
    [LOGIN_USER]: (state, user) => ({ ...state, ...user.user, isUserLoggedIn: true }),
    [LOGOUT_USER]: (state) => ({ ...state, user: {}, isUserLoggedIn: false }),
};

const local = state => state.user;

export const getCurrentUser = state => (local(state).user || {});

export default createReducer(initialState, actionHandlers);
