export const educations = [
    {
        id: 1,
        title: 'Master\'s degree in software architecture',
        description: 'Master\'s degree in software architecture.',
        date: 'July 2019',
    },
    {
        id: 2,
        title: 'General computer license',
        description: 'General computer science degree Mention web and mobile development.',
        date: 'July 2018',
    },
    {
        id: 3,
        title: 'Certificate of advanced technician SIO',
        description: 'BTS SIO (computer services to organizations) SLAM option (Software Solutions and Business Applications).',
        date: 'July 2017',
    },
    {
        id: 4,
        title: 'STI2D Bachelor\'s degree',
        description: 'Bachelor\'s degree STI2D option energy and environment option.',
        date: 'July 2015',
    },
];
