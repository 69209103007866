import React from 'react';

const Presentation = () => {
    return (
        <div className="presentation">
            <div className="presentation__title" data-aos="fade-right" data-aos-delay="100">Hi, <span className='color--primary'>I'm Dylan Dufay</span>
                <img className="bitmoji" src={require('../img/memoji.png')} alt="test" />
            </div>
            <div className="presentation__subtitle" data-aos="fade-right" data-aos-delay="200">Full-stack <span className='underline--primary'>developper</span></div>
            <div className="socials flex justify-center" data-aos="fade-right" data-aos-delay="300">
                <a href="https://www.linkedin.com/in/dylan-dufay-b6498013b/" target='_blank' rel='noopener noreferrer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="49.999" viewBox="0 0 50 49.999" fill="currentColor">
                    <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M11.192,50H.826V16.618H11.192ZM6,12.064a6.032,6.032,0,1,1,6-6.06A6.054,6.054,0,0,1,6,12.064ZM49.989,50H39.645V33.75c0-3.873-.078-8.839-5.39-8.839-5.39,0-6.215,4.208-6.215,8.56V50H17.685V16.618h9.942v4.554h.145a10.892,10.892,0,0,1,9.808-5.391C48.071,15.781,50,22.689,50,31.663V50Z" transform="translate(0 -0.001)" fill="currentColor"/>
                  </svg>

                </a>
                <a href="https://github.com/DDufay/ddufay" target='_blank' rel='noopener noreferrer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50.005" viewBox="0 0 50 50.005">
                    <path id="Icon_awesome-github" data-name="Icon awesome-github" d="M16.724,40.827c0,.207-.232.372-.524.372-.333.031-.565-.134-.565-.372,0-.207.232-.372.524-.372C16.462,40.423,16.724,40.589,16.724,40.827Zm-3.135-.465c-.071.207.131.445.433.507a.468.468,0,0,0,.625-.207c.06-.207-.131-.445-.433-.538A.512.512,0,0,0,13.589,40.361Zm4.456-.176c-.292.072-.494.269-.464.507.03.207.292.341.595.269s.494-.269.464-.476S18.337,40.155,18.044,40.186ZM24.677.563C10.7.563,0,11.451,0,25.792A25.885,25.885,0,0,0,17.087,50.526c1.29.238,1.744-.579,1.744-1.251,0-.641-.03-4.177-.03-6.349,0,0-7.056,1.551-8.538-3.081,0,0-1.149-3.009-2.8-3.784,0,0-2.308-1.623.161-1.592a5.3,5.3,0,0,1,3.891,2.668c2.208,3.991,5.907,2.844,7.349,2.161a5.8,5.8,0,0,1,1.613-3.485c-5.635-.641-11.321-1.479-11.321-11.426a7.94,7.94,0,0,1,2.379-6.09,10,10,0,0,1,.262-7.021c2.107-.672,6.956,2.792,6.956,2.792a23.239,23.239,0,0,1,12.661,0s4.849-3.474,6.956-2.792a10,10,0,0,1,.262,7.021c1.613,1.83,2.6,3.257,2.6,6.09,0,9.978-5.937,10.774-11.573,11.426a6.2,6.2,0,0,1,1.714,4.8c0,3.485-.03,7.8-.03,8.644,0,.672.464,1.489,1.744,1.251C43.165,47.072,50,37.259,50,25.792A25.021,25.021,0,0,0,24.677.563ZM9.8,36.225c-.131.1-.1.341.071.538.161.165.393.238.524.1.131-.1.1-.341-.071-.538C10.161,36.163,9.929,36.091,9.8,36.225ZM8.71,35.388c-.071.134.03.3.232.4a.3.3,0,0,0,.433-.072c.071-.134-.03-.3-.232-.4C8.942,35.253,8.78,35.284,8.71,35.388Zm3.266,3.681c-.161.134-.1.445.131.641.232.238.524.269.655.1.131-.134.071-.445-.131-.641C12.409,38.935,12.107,38.9,11.976,39.069Zm-1.149-1.52c-.161.1-.161.372,0,.61s.433.341.565.238a.481.481,0,0,0,0-.641C11.25,37.518,10.988,37.415,10.827,37.549Z" transform="translate(0 -0.563)" fill="currentColor"/>
                  </svg>

                </a>
                <a href="https://twitter.com/DylanDufay" target='_blank' rel='noopener noreferrer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="41.667" viewBox="0 0 50 41.667">
                    <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M44.861,13.765c.032.456.032.912.032,1.367,0,13.9-10.311,29.915-29.156,29.915A28.427,28.427,0,0,1,0,40.328a20.666,20.666,0,0,0,2.475.13A20.194,20.194,0,0,0,15.2,35.966a10.3,10.3,0,0,1-9.581-7.292,12.6,12.6,0,0,0,1.935.163,10.579,10.579,0,0,0,2.7-.358A10.452,10.452,0,0,1,2.03,18.159v-.13a10.123,10.123,0,0,0,4.632,1.335A10.712,10.712,0,0,1,3.49,5.3a28.891,28.891,0,0,0,21.129,11,12.166,12.166,0,0,1-.254-2.409A10.379,10.379,0,0,1,34.613,3.381,10.1,10.1,0,0,1,42.1,6.7a19.867,19.867,0,0,0,6.5-2.539A10.442,10.442,0,0,1,44.1,9.956,20.134,20.134,0,0,0,50,8.329a22.35,22.35,0,0,1-5.139,5.436Z" transform="translate(0 -3.381)" fill="currentColor"/>
                  </svg>

                </a>
            </div>
        </div>
    );
}

export default Presentation;
