import axios from 'axios';

import { Authentication } from './Authentication';

export class RequestAPI {
    constructor(baseURL) {
        this.baseURL = baseURL;
        this.axios = axios.create({
            baseURL: this.baseURL,
            timeout: 20000,
            headers: {},
        });
        const auth = new Authentication();
        auth.addWebTokenToRequest(this.axios);
        auth.checkRequestResponse(this.axios);
    }

    request(method, url, params = {}, data = null, headers = {}, format = 'json') {
        const doRequest = bearer => this.axios.request({
            baseURL: this.baseURL,
            url,
            method,
            data,
            headers: {
                ...bearer,
                ...headers,
            },
            params: {
                ...params,
            },
            responseType: format,
        });

        return doRequest();
    }
}

export default RequestAPI;
