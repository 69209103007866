export const projects = [
    // {
    //     id: 1,
    //     title: 'TF1 - Factory',
    //     description: 'TF1 - Factory est un site vitrine mettant en avant les projets de TF1.',
    //     date: 'Novembre 2020',
    //     technologies: 'HTML | CSS | JS',
    //     picture: 'useries/useries-590.jpg',
    //     detailPicture: "useries/useries-590.jpg",
    //     picturMin: "tf1-min.png",
    //     context: "This website gives you the ability to create unique color palettes for your design projects. With this custom tool built using ReactJS, you can easily select colors that match your style and needs. Simply navigate through this intuitive interface to choose the colors of your choice, and our tool will handle the rest. Download or copy and paste your favorite palettes for future use, or use them directly in your projects. With this custom color palette creation tool, you have the freedom to bring your ideas to life in a simple and effective way.",
    //     globalView1: "Navigate, create, edit, and delete a color palette from the home page.",
    //     globalView2: "From the palette editing page, you can generate an entire palette with just one click, remove colors, add them using the available form, and then save to keep your palette for later!",
    //     detailTechnologies: [
    //         {
    //             'Front-end': [
    //                 'Javascript',
    //                 'React',
    //                 'SCSS',
    //                 'HTML',
    //             ],
    //         },
    //         {
    //             'Databases': [
    //                 'Firebase',
    //                 'Localstorage',
    //             ]
    //         }
    //     ],
    //     // github: "https://github.com/DDufay/USeries",
    //     site: "https://www.tf1factory.fr/",
    // },
    {
        id: 1,
        title: 'MagnyBad',
        description: 'MagnyBad is mobile App already on stores for Magny-les-Hameaux badminton teams management.',
        date: 'February 2024',
        technologies: 'React Native | Firebase | Api Platform | Symfony',
        picture: 'magnybad/magnybad-590.png',
        detailPicture: "magnybad/magnybad-detail.png",
        picturMin: "magnybad/magnybad-min.png",
        context: "This project is a mobile application that allows the management of badminton teams in Magny-les-Hameaux. The application is already available on the App Store and Google Play Store. The application allows the management of teams, players, matches, and tournaments. The application is built with React Native, Firebase, API Platform and Symfony.",
        globalView1: "This project aims to be used on both a mobile device. Allowing users to login or register with their email and password. The user can then access the different sections of the application.",
        globalView2: "Modern and sober design, the users can easily navigate through the different sections of the application.",
        globalView1Picture: 'magnybad/global-view-2.png',
        globalView2Picture: 'magnybad/global-view-1.png',
        site: "https://magnybad.dylandufay.fr/",
        detailTechnologies: [
            {
                'Front-end': [
                    'Typescript',
                    'React Native',
                    'TailwindCSS',
                ],
            },
            {
                'Databases': [
                    'Firebase',
                    'Api Platform',
                    'Symfony'
                ]
            }
        ],
        // details: [
        //     {
        //         id: 1,
        //         title: "Présentation du projet",
        //         text: "L'objectif de ce projet est de proposer un catalogue de films/séries à un utilisateur. Les" +
        //             " données sont récupérées depuis l'API 'themoviedb' et affichées grâce à une interface réalisée" +
        //             " en ReactJS. Pour ce qui concerne le design, j'ai dans un premier temps fait des maquettes en" +
        //             " utilisant Adobe XD. Le but est d'avoir une application sobre et moderne avec un UI/UX optimal." +
        //             " Cette application contient un système d'utilisateur réalisé avec firebase permettant de" +
        //             " consulter ses films/séries favoris.",
        //     },
        //     {
        //         id: 2,
        //         title: "Version mobile",
        //         text: "Ce projet a pour vocation à être utilisé sur un ordinateur mais aussi sur mobile. C'est même" +
        //             " une approche mobile first que j'ai mise en place sur ce projet, afin d'avoir un UX/UI optimal" +
        //             " sur mobile.",
        //         picture: ["useries/useries-films.png", "useries/useries-profil.png"],
        //     },
        //     {
        //         id: 3,
        //         title: "Bilan du projet",
        //         text: "Ce projet m'a permis de monter en compétences sur ReactJS, firebase et l'aspect Serverless." +
        //             " J'ai découvert et acquis des connaissances dans le domaine du design en utilisant Adobe XD" +
        //             " pour mes maquettes.",
        //     },
        // ],
    },
    {
        id: 2,
        title: "D-notes",
        description: "Notes manager",
        date: "Work in progress",
        technologies: "Symfony | Api platform | Swift",
        picture: "d-notes/d-notes.png",
        detailPicture: "d-notes/d-notes.png",
        picturMin: "d-notes/d-notes-min.png",
    },
    {
        id: 3,
        title: 'USeries',
        description: 'USeries est une application web permettant le suivi de films/séries.',
        date: 'Avril 2020',
        technologies: 'ReactJS | Firebase | Adobe XD',
        picture: 'useries/useries-590.jpg',
        detailPicture: "useries/useries-detail.png",
        picturMin: "useries-min.png",
        context: "The objective of this project is to provide a movie/TV show catalog to the user. The data is retrieved from the 'themoviedb' API and displayed through a user interface built with ReactJS. Regarding the design, I initially created mockups using Adobe XD. The goal is to have a modern, simple and optimal UI/UX application. This app features a user system implemented with firebase that allows users to view their favorite movies/TV shows.",
        globalView1: "This project aims to be used on both a computer and a mobile device. I have even implemented a mobile-first approach in this project in order to have an optimal UX/UI on mobile.",
        globalView2: "This project allowed me to improve my skills in ReactJS, firebase, and serverless design. I discovered and gained knowledge in design by using Adobe XD for my mockups.",
        globalView1Picture: 'useries/333.png',
        globalView2Picture: 'useries/useries-mobile.png',
        detailTechnologies: [
            {
                'Front-end': [
                    'Javascript',
                    'React',
                    'SCSS',
                    'HTML',
                ],
            },
            {
                'Databases': [
                    'Firebase',
                    'TheMovieDB'
                ]
            }
        ],
        // details: [
        //     {
        //         id: 1,
        //         title: "Présentation du projet",
        //         text: "L'objectif de ce projet est de proposer un catalogue de films/séries à un utilisateur. Les" +
        //             " données sont récupérées depuis l'API 'themoviedb' et affichées grâce à une interface réalisée" +
        //             " en ReactJS. Pour ce qui concerne le design, j'ai dans un premier temps fait des maquettes en" +
        //             " utilisant Adobe XD. Le but est d'avoir une application sobre et moderne avec un UI/UX optimal." +
        //             " Cette application contient un système d'utilisateur réalisé avec firebase permettant de" +
        //             " consulter ses films/séries favoris.",
        //     },
        //     {
        //         id: 2,
        //         title: "Version mobile",
        //         text: "Ce projet a pour vocation à être utilisé sur un ordinateur mais aussi sur mobile. C'est même" +
        //             " une approche mobile first que j'ai mise en place sur ce projet, afin d'avoir un UX/UI optimal" +
        //             " sur mobile.",
        //         picture: ["useries/useries-films.png", "useries/useries-profil.png"],
        //     },
        //     {
        //         id: 3,
        //         title: "Bilan du projet",
        //         text: "Ce projet m'a permis de monter en compétences sur ReactJS, firebase et l'aspect Serverless." +
        //             " J'ai découvert et acquis des connaissances dans le domaine du design en utilisant Adobe XD" +
        //             " pour mes maquettes.",
        //     },
        // ],
        github: "https://github.com/DDufay/USeries",
        site: "http://useries.dylandufay.fr/",
    },
    {
        id: 4,
        title: 'Color Palette',
        description: 'Color Palette est une application web permettant de créer des palettes de couleurs.',
        date: 'Juin 2020',
        technologies: 'ReactJS | LocalStorage | StyledComponent',
        picture: 'color-palette/color-palette.jpg',
        picturMin: "colorpalette-min.png",
        detailPicture: "color-palette/color-palette-detail.jpg",
        context: "This website gives you the ability to create unique color palettes for your design projects. With this custom tool built using ReactJS, you can easily select colors that match your style and needs. Simply navigate through this intuitive interface to choose the colors of your choice, and our tool will handle the rest. Download or copy and paste your favorite palettes for future use, or use them directly in your projects. With this custom color palette creation tool, you have the freedom to bring your ideas to life in a simple and effective way.",
        globalView1: "Navigate, create, edit, and delete a color palette from the home page.",
        globalView2: "From the palette editing page, you can generate an entire palette with just one click, remove colors, add them using the available form, and then save to keep your palette for later!",
        globalView1Picture: 'color-palette/1.PNG',
        globalView2Picture: 'color-palette/color-palette-create.png',
        detailTechnologies: [
            {
                'Front-end': [
                    'Javascript',
                    'React',
                    'SCSS',
                    'HTML',
                ],
            },
            {
                'Databases': [
                    'Firebase',
                    'Localstorage',
                ]
            }
        ],
        github: "https://github.com/DDufay/color-palette",
        site: "http://color-palette.dylandufay.fr/",
    },
    {
        id: 5,
        title: 'Foothub',
        description: 'Foothub est une application mobile permettant de consulter des résultats footballistique.',
        date: 'Novembre 2016 - Février 2017',
        technologies: 'Ionic',
        picture: 'foothub.png',
        detailPicture: 'foothub-detail.jpg',
        picturMin: "foothub-min.png",
        details: [
            {
                id: 1,
                title: "Présentation du projet",
                text: ' L\'application comporte plusieurs sections: les matchs en direct, les matchs suivis par l\'utilisateur' +
                    ' connecté, le classement pour chaque ligue de football.'
            }
        ]
    },
];
