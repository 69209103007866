import React from 'react';
import { Link } from 'react-router-dom';

import { projects } from "../actions/projectsTemp";


const Projects = () => {
    // const projects = useSelector(getProjects);

    // useEffect(() => {
    //     dispatch(loadProjects());
    // }, [dispatch]);

    return (
        <div className="projects">
            <div className="section__title">Projets</div>
            <div className="flex projects-list">
                {projects && projects.map((project, key) =>
                <div key={project.id} className="project" style={{ flexDirection: key % 2 ? 'row-reverse' : 'row' }} data-aos="fade-right" data-aos-delay={`${project.id*200}`}>
                    <div className="project--picture">
                        <img src={require(`../img/${project.picturMin}`)} alt="dylan dufay"/>
                    </div>

                    <div className="project--text">
                        <div className="flex flex--wrap justify-space-between project--text--header">
                            <div className="project--text--title">{project.title}</div>
                            <div className="project--text-technologies align-self-center">
                                {project.technologies}
                            </div>
                        </div>
                        <div className="project--text--date">{project.date}</div>
                        <div className="project--text--description">
                            {project.description}
                        </div>

                        {project.site && <Link to={`/project/${project.title}`} className="btn-see" href={project.site} target="_self">Voir</Link>}
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

export default Projects;
