import {toastr} from "react-redux-toastr";

import {makeActionCreator} from "../helpers/helper";
import api from "../lib/api";

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const loginUser = makeActionCreator(LOGIN_USER, 'user');
export const logoutUser = makeActionCreator(LOGOUT_USER, 'user');

export const login = (data, history) => dispatch => {
   // call api to login user
    api.account.login(data.username, data.password)
        .then(response => {
            api.authentication().setUserTokens(response.data.accessToken, response.data.refreshToken);
            const userData = api.authentication().getUserData();
            dispatch(loginUser(userData));
            history.push("/dashboard");
            toastr.success('Connecté', 'Vous pouvez accéder au back office');
        })
        .catch(error => toastr.error('Erreur', 'Mauvais identifiants'))
};

export const logout = history => dispatch => {
    api.authentication().deleteTokens(true);
    history.push("/");
    dispatch(logoutUser());
};
