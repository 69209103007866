import React from "react";

import { works } from "../actions/works";
import { educations } from "../actions/educations";

export const Works = () => {
    return <div className="works">
        <div className="works-title section__title">Works | Education</div>
        <div className="flex works__container">
            <div className="flex flex--50 works-list" data-aos="fade-right">
                {works.map(work =>
                    <div className="work" key={work.id}>
                        <div className="flex flex--column flex--column">
                            <div>
                                <div className="work__title"><span>{work.title}</span></div>
                                <div className="work__title"><span>{work.company}</span></div>
                                <div className="work__date">{work.date}</div>
                            </div>
                            <div className="work__description">
                                {work.description}
                            </div>
                        </div>
                        <div className="work__technologies">
                            {work.technologies}
                        </div>
                    </div>)}
            </div>
            <div className="list__separator" data-aos="fade-right" />
            <div className="flex flex--50 flex--column education-list" data-aos="fade-right">
                {educations.map(education =>
                    <div className="education" key={education.id}>
                        <div>
                            <div className="education__title"><span>{education.title}</span></div>
                            <div className="education__date">{education.date}</div>
                        </div>
                        <div className="education__description">
                            {education.description}
                        </div>
                    </div>)}
            </div>
        </div>
    </div>
};
