import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import store from './store';
import App from './App';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import ProjectDetail from './components/ProjectDetail';

ReactDOM.render(
  <Provider store={store}>
      <div>
          <BrowserRouter>
              <Switch>
                  <Route exact path="/" component={() => <App/>}/>
                  <Route exact path="/project/:slug" component={() => <ProjectDetail/>}/>
              </Switch>
          </BrowserRouter>
          <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
          />
      </div>
</Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
