import { createReducer } from "../helpers/helper";
import { SET_PROJECTS_LIST } from "../actions/projects";

const initialState = {
    projects: []
};

const actionHandlers = {
    [SET_PROJECTS_LIST]: (state, projects) => ({ ...state, ...projects }),
};

const local = state => state.project;

export const getProjects = state => (local(state).projects || []);

export default createReducer(initialState, actionHandlers);
